import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import imgTitle from "../images/img_title.png";
import imgRealworldLogo from "../images/realworld-logo.png";

import "../styles/index.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <div className="top-bar">
        <div className="container container-padding">
          <a href="https://realworld.to" target="_blank">
            <img
              src={imgRealworldLogo}
              alt="Realworld"
              width="162"
              height="36"
            />
          </a>
        </div>
      </div>
      <main>
        <div className="container">
          <div className="top-banner">
            <img src={imgTitle} alt="banner" />
          </div>
          <div className="cta-button-container">
            <a
              className="cta-button"
              href="https://realworld.to/stories/-aWM8xd1-qw662wj?utm_source=offline&utm_medium=paid-offline&utm_campaign=crimeposter2022&utm_content=research-bt"
              target="_blank"
            >
              <StaticImage
                src="../images/img_btn_cta.png"
                alt="현장 조사 버튼."
                width={150}
              />
            </a>
          </div>
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ZjVUZ5b1ves"
              title="<더스키호텔 살인사건> 플레이 방법"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <a
            className="img-description"
            href="https://youtu.be/ZjVUZ5b1ves"
            target="_blank"
          >
            <StaticImage
              src="../images/img_event_desc_mod.png"
              alt="본 영상에 기대평을 남겨주세요! 추첨을 통해 크라임씬 플레이 코드를 드립니다."
            />
          </a>
          <a
            className="gamelist-banner"
            href="https://uniquegoodcompany.notion.site/f0ae4e21ca38437dbd5b1d26bb85fa09"
            target="_blank"
          >
            <StaticImage
              src="../images/img_banner_howto.png"
              alt="선필독 후게임. 리얼 크라임씬 시리즈 플레이 방법. 자세히보기 버튼."
            />
          </a>
          <a
            className="howto-banner"
            href="https://realworld.to/stories/-aWM8xd1-qw662wj?utm_source=offline&utm_medium=paid-offline&utm_campaign=crimeposter2022&utm_content=bottom_banner"
            target="_blank"
          >
            <StaticImage
              src="../images/img_banner_landing.png"
              alt="드디어 돌아왔다! 리얼 크라임씬 시리즈 모음. 바로가기 버튼."
            />
          </a>
        </div>
      </main>
      <footer>
        <div className="container container-padding">
          <h4>주식회사 유니크굿컴퍼니</h4>
          <dl>
            <div className="footer-group">
              <dt>사업자등록번호</dt>
              <dd>524-88-00746</dd>
            </div>
            <div className="footer-group">
              <dt>통신판매업신고번호</dt>
              <dd>2018-서울성동-0760호</dd>
            </div>
            <div className="footer-group">
              <dt>대표이사</dt>
              <dd>이은영,송인혁</dd>
            </div>
            <div className="footer-group">
              <dt>주소</dt>
              <dd>서울특별시 성동구 연무장13길 8, 메리히어 3층</dd>
            </div>
            <div className="footer-group">
              <dt>전화</dt>
              <dd>070-8706-1010</dd>
            </div>
          </dl>
          <h4>Contact</h4>
          <div className="footer-sns mb-3">
            <a href="https://twitter.com/Realworld_to" target="_blank">
              <StaticImage
                src="../images/twitter_icon.svg"
                alt="리얼월드 트위터 바로가기"
              />
            </a>
            <a href="https://www.instagram.com/realworld.to/" target="_blank">
              <StaticImage
                src="../images/instagram-icon.svg"
                alt="리얼월드 인스타그램 바로가기"
              />
            </a>
            <a href="https://blog.naver.com/uniquegoodcompany" target="_blank">
              <StaticImage
                src="../images/naver-icon.svg"
                alt="유니크굿컴퍼니 블로그 바로가기"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCf0yUWNWZF_0sfpeX3PhEcQ"
              target="_blank"
            >
              <StaticImage
                src="../images/youtube-icon.svg"
                alt="리얼월드 유튜브 채널 바로가기"
              />
            </a>
            <a href="https://discord.gg/mjJkRDUpD5" target="_blank">
              <StaticImage
                src="../images/discord-icon.svg"
                alt="리얼월드 공식 디스코드 바로가기"
                width={34}
                height={34}
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
